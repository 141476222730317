<template>
  <div class="auditor-container">
    <!-- <router-link to="/auditor/login">Login</router-link>
    <router-link to="/auditor/dashboard">Dashboard</router-link> -->
    <router-view></router-view>
  </div>
</template>

<script>
// Import Bootstrap CSS and JavaScript here
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
export default {
  mounted() {
  },
  props: [],
  async created() {
    localStorage.removeItem("publicUrlUser");
    localStorage.removeItem("token");
    localStorage.removeItem("publicexamAuthToken");
    localStorage.removeItem("userPermissions");

    var auditorAuthToken = localStorage.getItem("auditorAuthToken");
    if (auditorAuthToken) {
      this.$router.push('/auditor/dashboard');
    } else {
      this.$router.push('/auditor/login');
    }
    // this.$router.push({ name: 'your-route-name' });
  },
  components: {},
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
/* .auditor-container{
background-color: #0da2a2;
} */
</style>
